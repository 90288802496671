import React from "react";
import WhiteMainLogo from "../../Images/WhiteMainLogo.png";
import "./sidebar.css"
import "../../pages/agent/common.css"
import { useSelector } from "react-redux";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const { user, stepComplete } = useSelector((state) => state.auth);

  const navigate = useNavigate()

const date=new Date()


  return (
    <div className="LeftAgentAccount">
      <div className="TopAgentLogin">
        <img className="profl_lg" src={WhiteMainLogo} alt="" onClick={()=> navigate('/')} style={{cursor: "pointer"}}/>
        {/* <img className="desklogo" src={WhiteMainLogo} alt="" /> */}
            {/* <img className="moblogo" src={Mobilelogo} alt="" /> */}
      </div>
      <div className="Agent_account_Data">
        <ul id="progressbar" className="text-center ">
          <li
            // className="step0 active activedot"
            className={stepComplete && stepComplete >= 1 ? "active step0" : user && user?.onBoardingSteps >= 1 ? "active step0" : "step0"}
          >
            <a href="#">
              <div className="OpenAccount">
                <h4>Create your account</h4>
                <h6>Let’s get your account setup</h6>
              </div>
            </a>
          </li>

          <li
            className={stepComplete && stepComplete >= 2 ? "active step0" : user && user?.onBoardingSteps >= 2 ? "active step0" : "step0"}
          >
            <a href="#">
              <div className="OpenAccount">
                <h4>Let’s verify your account</h4>
                <h6>We’ll send you a code</h6>
              </div>
            </a>
          </li>

          <li
            className={stepComplete && stepComplete >= 3 ? "active step0" : user && user?.onBoardingSteps >= 3 ? "active step0" : "step0"}
          >
            <a href="#">
              <div className="OpenAccount">
                <h4>Complete your profile</h4>
                <h6>Let’s get your profile looking good</h6>
              </div>
            </a>
          </li>

          <li
            className={stepComplete && stepComplete >= 4 ? "active step0" : user && user?.onBoardingSteps >= 4 ? "active step0" : "step0"}
          >
            <a href="#">
              <div className="OpenAccount">
                <h4>Business Details</h4>
                <h6>Tell us more about your business</h6>
              </div>
            </a>
          </li>

          <li
            className={stepComplete && stepComplete >= 5 ? "active step0" : user && user?.onBoardingSteps >= 5 ? "active step0" : "step0"}
          >
            <a href="#">
              <div className="OpenAccount">
                <h4>Invite your team</h4>
                <h6>Start collaborating with your team</h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="creatAgentLogin">
        <h6>© Reazy {date.getFullYear()}</h6>
        <div className="support">
          <MailOutlineIcon />
          <h6>support@reazy.com.au</h6>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
